<div class="header-content row">
  <div class="col-md-3">
    <img class="side-image w-100" [src]="image" alt="">
  </div>
  <div class="col-md-9 text-left">
    <h1 *ngIf="headlineText" class="headline"><b>{{headlineText}}</b></h1>
    <h2 *ngIf="searchingText" class="menu">{{searchingText}}</h2>
    <p *ngIf="descriptionText" class="menu font-weight-normal">{{descriptionText}}</p>
    <p *ngIf="footerText" class="menu">{{footerText}}</p>
    <a *ngIf="showJoin" href="https://foursource.com/signup" target="_blank" rel="noopener" class="btn btn-success">Join
      for
      free</a>
  </div>
</div>
