/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./item-card.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i3 from "@angular/material/card";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "./item-card.component";
var styles_ItemCardComponent = [i0.styles];
var RenderType_ItemCardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ItemCardComponent, data: {} });
export { RenderType_ItemCardComponent as RenderType_ItemCardComponent };
export function View_ItemCardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "mat-card", [["class", "mat-card"]], null, null, null, i2.View_MatCard_0, i2.RenderType_MatCard)), i1.ɵdid(1, 49152, null, 0, i3.MatCard, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 5, "a", [["class", "card-style"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 3, "mat-card-content", [["class", "mat-card-content"]], null, null, null, null, null)), i1.ɵdid(5, 16384, null, 0, i3.MatCardContent, [], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.link; _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 3).target; var currVal_1 = i1.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = _co.itemName; _ck(_v, 7, 0, currVal_3); }); }
export function View_ItemCardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-item-card", [], null, null, null, View_ItemCardComponent_0, RenderType_ItemCardComponent)), i1.ɵdid(1, 49152, null, 0, i6.ItemCardComponent, [], null, null)], null, null); }
var ItemCardComponentNgFactory = i1.ɵccf("app-item-card", i6.ItemCardComponent, View_ItemCardComponent_Host_0, { itemName: "itemName", link: "link", image: "image" }, {}, []);
export { ItemCardComponentNgFactory as ItemCardComponentNgFactory };
