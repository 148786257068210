/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header-image.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./header-image.component";
var styles_HeaderImageComponent = [i0.styles];
var RenderType_HeaderImageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderImageComponent, data: {} });
export { RenderType_HeaderImageComponent as RenderType_HeaderImageComponent };
function View_HeaderImageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h1", [["class", "headline"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.headlineText; _ck(_v, 2, 0, currVal_0); }); }
function View_HeaderImageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h2", [["class", "menu"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.searchingText; _ck(_v, 1, 0, currVal_0); }); }
function View_HeaderImageComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "menu font-weight-normal"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.descriptionText; _ck(_v, 1, 0, currVal_0); }); }
function View_HeaderImageComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "menu"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.footerText; _ck(_v, 1, 0, currVal_0); }); }
function View_HeaderImageComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "btn btn-success"], ["href", "https://foursource.com/signup"], ["rel", "noopener"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Join for free"]))], null, null); }
export function View_HeaderImageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "header-content row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "col-md-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", ""], ["class", "side-image w-100"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 10, "div", [["class", "col-md-9 text-left"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderImageComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderImageComponent_2)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderImageComponent_3)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderImageComponent_4)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderImageComponent_5)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.headlineText; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.searchingText; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.descriptionText; _ck(_v, 9, 0, currVal_3); var currVal_4 = _co.footerText; _ck(_v, 11, 0, currVal_4); var currVal_5 = _co.showJoin; _ck(_v, 13, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.image; _ck(_v, 2, 0, currVal_0); }); }
export function View_HeaderImageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header-image", [], null, null, null, View_HeaderImageComponent_0, RenderType_HeaderImageComponent)), i1.ɵdid(1, 49152, null, 0, i3.HeaderImageComponent, [], null, null)], null, null); }
var HeaderImageComponentNgFactory = i1.ɵccf("app-header-image", i3.HeaderImageComponent, View_HeaderImageComponent_Host_0, { image: "image", headlineText: "headlineText", searchingText: "searchingText", descriptionText: "descriptionText", footerText: "footerText", showJoin: "showJoin" }, {}, []);
export { HeaderImageComponentNgFactory as HeaderImageComponentNgFactory };
