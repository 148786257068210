import { environment } from '../environments/environment';

export const CONFIGURATION: any = {
  DEFAULT_LANG: 'en',
  // TRACK_ERROR: true,
  // ENV: environment,
  DOMAINS_API: [
    { HOST: environment.api_url, KEY: '@api' },
    { HOST: environment.static_content, KEY: '@static' },
    { HOST: environment.app_url, KEY: '@local' },
  ],

  BASIC: {
    USERNAME: 'foursource',
    PASSWORD: '8Kr8qHgzfcTLpDC5',
    DEFAULT: 'f4s0u45e',
  },

  SECRET_SALT: {
    PASSWORD: 'bqyg3hi3VVU8tWGu',
    DEFAULT: 'f4s0u45e',
  },

  ANALYTICS_DOMAINS: [
    {
      domain: 'fourall-stagingdev10.foursource.pt',
      code: 'UA-90460691-1',
      agency: 'AW-779945857/YLFuCI6T448BEIGP9PMC',
    },
    {
      domain: 'foursource.com',
      code: 'UA-90460691-2',
      agency: 'AW-779945857/CzmACLutmY0BEIGP9PMC',
    },
  ],

  ENDPOINTS: {
    IMAGES_PATH: '@static:/images/:domain/:section/:size/:path',
    STATICIMAGESDOMAIN: '@static:/images/',
    STATICFILESDOMAIN: '@static:/files/',
    STATICCSVDOMAIN: '@static:/',
    PROFILE: '@api:/:entity/:uid',
    CATEGORIES_JSON: '@api:/:subdomain',
    CATEGORY_DETAILS: '@api:/:subdomain/:uid',
    CATEGORY_COUNTRY_DETAILS: '@api:/:subdomain/:category/countries/:country',
    CATEGORY_CITY_DETAILS: '@api:/:subdomain/:category/countries/:country/cities/:city',
    COUNTRY_FOOTER_INFO: '@api:/:subdomain/:category/countries',
    CITY_FOOTER_INFO: '@api:/:subdomain/:category/countries/:country/cities',
    ALIVE: '@api:/alive',
    PROFILE_ENTITY: '@api:/:entity/:uid',
    FABRIC_GROUPS: '@api:/:entity/fabric-types',
    FABRIC_GROUP_DETAILS: '@api:/:entity/fabric-types/:uid',
    FABRIC_GROUP_COUNTRIES: '@api:/:entity/fabric-types/:uid/countries',
    FABRIC_GROUP_COUNTRY_DETAILS: '@api:/:entity/fabric-types/:uid/countries/:country',
    FABRIC_GROUP_COUNTRY_CITY_DETAILS: '@api:/:entity/fabric-types/:uid/countries/:country/cities/:city',
  },

  BE_CONFIGS: {
    FABRIC_ENTITY: 'fabric-suppliers',
    MANUFACTURER_ENTITY: 'manufacturers',
  },
};
