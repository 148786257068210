import { AfterViewInit, Directive, ElementRef, HostListener, Inject, Input, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

const OVERLAY_ID: string = 'header-overlay';

@Directive({
  selector: '[parallaxHeader]',
})
export class ParallaxHeaderDirective implements AfterViewInit {
  @Input() public parallaxScale = 10;
  @Input() public translateTop = true;

  @HostListener('window:scroll', ['$event'])
  public onScroll(event) {
    this.scrollHandler(event);
  }

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) {}

  public ngAfterViewInit(): void {
    const transition: (elements?: string, timeInSeconds?: number) => string = (
      elements: string = 'all',
      timeInSeconds: number = 0.2
    ) => `${elements} ${timeInSeconds}s cubic-bezier(0.22, 1, 0.36, 1)`;

    this.createOverlay();
    this.elementRef.nativeElement.style.position = 'fixed';
    this.elementRef.nativeElement.style.overflow = 'hidden';
    this.elementRef.nativeElement.style.transition = transition('transform');
    this.getImageElement().style.transition = `${transition('transform')}, ${transition('opacity', 1.5)}`;
    this.getOverlayElement().style.transition = `${transition('transform', 0.3)}`;
  }

  private scrollHandler(event: Event): void {
    const eventTop: number = event.target['scrollingElement'].scrollTop;
    const scale: number = 1 + (event.target['scrollingElement'].scrollTop || 0) / 5000;
    const opacity: number = eventTop > 100 ? 1 - (eventTop - 100 || 0) / 200 : 1;
    if (this.translateTop) {
      const top: number = -(eventTop / this.parallaxScale);
      this.elementRef.nativeElement.style.transform = `translateY(${top}px)`;
    }
    this.getImageElement().style.transform = `scale(${scale < 1 ? 1 : scale})`;
    this.getOverlayElement().style.transform = `scale(${scale < 1 ? 1 : scale})`;
    this.getImageElement().style.opacity = opacity < 0 ? '0' : '1';
  }

  private createOverlay(): void {
    const background = 'linear-gradient(0deg, rgba(255,255,255,1) 20%, rgba(255,255,255,0) 100%)';
    const overlay = this.document.createElement('div');
    overlay.id = OVERLAY_ID;
    overlay.style.position = 'absolute';
    overlay.style.top = '0';
    overlay.style.width = '100%';
    overlay.style.height = '120%';
    overlay.style.background = background;
    this.renderer.appendChild(this.elementRef.nativeElement, overlay);
  }

  private getImageElement(): HTMLImageElement {
    return this.elementRef.nativeElement.getElementsByTagName('img')[0];
  }

  private getOverlayElement(): HTMLElement {
    return this.elementRef.nativeElement.querySelector(`#${OVERLAY_ID}`);
  }
}
