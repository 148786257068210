import { Component, OnInit, Inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CONSTANTS, makeSymbolPath, UrlResolver } from '@app/common';
import { SeoService } from '@app/common/services/seo.service';
import { get } from 'lodash';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CategoriesService } from '../../categories.service';
import { CategoryDetailsModel } from '../../categories.typings';
import { DOCUMENT } from '@angular/common';

@Component({
  templateUrl: './category-view.component.html',
  styleUrls: ['./category-view.component.scss'],
})
export class CategoryViewComponent implements OnInit {
  public categoryDetails$: Observable<CategoryDetailsModel>;
  public entityImagePath: string;

  constructor(
    private _url: UrlResolver,
    private _router: Router,
    private _localService: CategoriesService,
    private _activeRoute: ActivatedRoute,
    private _title: Title,
    private _meta: Meta,
    private _seoService: SeoService,
    @Inject(DOCUMENT) private _dom: Document
  ) {
    this._seoService.createCanonicalURL();
  }

  public ngOnInit() {
    this._setEntityPaths();

    this._activeRoute.params.forEach((params: Params) => {
      const category = get(params, 'category', null);
      this.categoryDetails$ = this._localService.getCategoryDetails(category).pipe(
        tap(res => {
          this._title.setTitle(res.name + ' Manufacturers | FOURSOURCE');
          const el = this._dom.createElement('p');
          const el_2 = this._dom.createElement('p');
          const html = '&#10003;';
          const html_2 = '&#9654;';
          el.innerHTML = html;
          el_2.innerHTML = html_2;
          this._meta.updateTag({
            name: 'description',
            content:
              res.name +
              ' Manufacturers Directory ' +
              el.innerHTML +
              ' Industry specific search ' +
              el.innerHTML +
              ' Access to a global database ' +
              el.innerHTML +
              ' Digital supplier showrooms ' +
              el_2.innerHTML +
              ' Sign up FREE',
          });
        })
      );
    });
  }

  public getImgPath(uid: string) {
    return this.entityImagePath + uid + '.jpg';
  }

  public getBackgroundImg(uid: string) {
    return '/assets/img/manufacturerdefault.jpg';
  }

  private _setEntityPaths() {
    const staticDomain = this._url.get(makeSymbolPath([CONSTANTS.ENDPOINTS, CONSTANTS.STATICIMAGESDOMAIN]));

    this.entityImagePath = `${staticDomain}categories/md/`;
  }
}
