<ng-container *ngIf="(categoryDetails$ | async) as category else loadingState">
  <header class="w-100">
    <img class="w-100" height="400px" [src]="getBackgroundImg(category.uid)" alt="">
  </header>
  <div class="container">
    <div class="content-container-fat main-container pb-0">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a [routerLink]="'/manufacturers'">Manufacturers directory</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">{{category.name}}</li>
        </ol>
      </nav>
      <div class="header-content row">
        <div class="col-md-3">
          <img class="w-100" [src]="getImgPath(category.uid)" alt="">
        </div>
        <div class="col-md-9 text-left">
          <h1 class="headline"><b>{{ category.name}} Manufacturers</b></h1>
          <h2 class="menu">Are you searching for {{ category.uid }} manufacturers in the apparel industry?</h2>
          <p class="menu font-weight-normal">Then you can find below {{category.totalManufacturers}} {{category.uid}} manufacturers from {{category.totalCountries}} countries in {{category.totalCities}} cities all over the world. Select one of the countries or cities below, to find your perfect manufacturer for {{category.uid}} within our manufacturer network. </p>
          <p class="menu">FOURSOURCE is the world’s largest sourcing network in the apparel industry.</p>
          <a href="https://foursource.com/signup" target="_blank" rel="noopener" class="btn btn-success">Join for free</a>
        </div>
      </div>
      <div class="body-content">
        <h3 class="menu">{{ category.name }} manufacturer:</h3>
        <div class="country-section" *ngFor="let country of category.list">
          <a class="menu" class="country-link" [routerLink]="'/manufacturers/' + category.uid + '/' + country.uid">{{country.name}}</a>
          <div class="row">
            <a *ngFor="let city of country.cities; let i = index;" [routerLink]="'/manufacturers/' + category.uid + '/' + country.uid + '/' + city.uid" class="city col-md-4" [class.city-background]="i%2 === 0">
              {{city.name}}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #loadingState>
  <div class="container">
    <div class="content-container-fat main-container">
      <img class="img-fluid" src="/src/assets/img/indexpagesplaceholder.svg" alt="">
    </div>
  </div>
</ng-template>
