import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UrlResolver } from '@app/common';
import { SeoService } from '@app/common/services/seo.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CountryDetailsParams, FabricTypesService } from '@app/containers/categories/fabrics/fabric-types.service';
import { Country, CountryCityDetails } from '@app/containers/categories/fabrics/fabric-types.typings';

@Component({
  templateUrl: './fabric-type-detail.component.html',
  styleUrls: ['./fabric-type-detail.component.scss'],
})
export class FabricTypeDetailComponent implements OnInit {
  public categoryCountryDetails$: Observable<CountryCityDetails>;
  public footerInfoDetails$: Observable<Country[]>;
  public categoryImagePath: string;
  public countryImagePath: string;
  public isCity = false;
  public environment = environment;

  constructor(
    private localService: FabricTypesService,
    private activeRoute: ActivatedRoute,
    private title: Title,
    private meta: Meta,
    private seoService: SeoService,
    @Inject(DOCUMENT) private _dom: Document
  ) {
    this.seoService.createCanonicalURL();
  }

  public ngOnInit() {
    this.setEntityPaths();

    this.activeRoute.params.forEach((params: Params) => {
      const url_params: CountryDetailsParams = {
        fabric_group_uid: params['fabric-type'],
        country_uid: params.country ? params.country : '',
        city_uid: params.city,
      };

      this.isCity = !!params.city;

      this.categoryCountryDetails$ = this.localService.getFabricGroupCountryCityDetails(url_params).pipe(
        tap(res => {
          if (res && res.city) {
            this.title.setTitle(
                res.name + ' Fabric suppliers in ' + res.city.name + '/' + res.country.name + ' | FOURSOURCE'
            );
          } else {
            this.title.setTitle(res.name + ' Fabric suppliers ' + res.country.name + ' | FOURSOURCE');
          }
          const el = this._dom.createElement('p');
          const el_2 = this._dom.createElement('p');
          const html = '&#10003;';
          const html_2 = '&#9654;';
          el.innerHTML = html;
          el_2.innerHTML = html_2;
          this.meta.updateTag({
            name: 'description',
            content:
              res.name +
              ' suppliers in ' +
              (res.city ? res.city.name + '/' + res.country.name : res.country.name) +
              ' ' +
              el.innerHTML +
              ' Industry specific search ' +
              el.innerHTML +
              ' Access to a global database ' +
              el.innerHTML +
              ' Digital supplier showrooms ' +
              el_2.innerHTML +
              ' Sign up FREE',
          });
        })
      );
      if (this.isCity) {
        this.footerInfoDetails$ = this.localService.getFooterInfo(url_params);
      }
    });
  }

  public getImgPath(uid: string) {
    return this.categoryImagePath + uid + '.jpg';
  }

  public getBackgroundImg(iso_code: string) {
    return this.countryImagePath + iso_code + '.jpg';
  }

  private setEntityPaths() {
    this.categoryImagePath = this.localService.getStaticImagePath();
    this.countryImagePath = this.localService.getStaticImagePath('countries');
  }
}
