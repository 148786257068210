import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-header-image',
  templateUrl: './header-image.component.html',
  styleUrls: ['./header-image.component.scss']
})
export class HeaderImageComponent {
  @Input() public image: string;
  @Input() public headlineText: string;
  @Input() public searchingText: string;
  @Input() public descriptionText: string;
  @Input() public footerText: string;
  @Input() public showJoin = true;
}
