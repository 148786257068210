<ng-container *ngIf="(fabricGroupsDetails | async) as fabricGroup else loadingState">
  <header parallaxHeader class="w-100 fixed-header">
    <img class="w-100" height="400px" [src]="getBackgroundImg()" alt="">
  </header>
  <div class="container">
    <div class="blurred content-container-fat main-container pb-0">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a [routerLink]="'/fabrics'">Suppliers directory</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">{{fabricGroup.name}}</li>
        </ol>
      </nav>
      <app-header-image [image]="getImgPath(fabricGroup.uid)"
                        headlineText="{{ fabricGroup.name}} Suppliers"
                        searchingText="Are you searching for {{ fabricGroup.name }} suppliers in the apparel industry?"
                        descriptionText="Then you can find below {{fabricGroup.total_fabrics}} {{fabricGroup.name}} suppliers from
            {{fabricGroup.total_countries}} countries in {{fabricGroup.total_cities}} cities all over the world.
            Select one of the countries or cities below, to find your perfect supplier for {{fabricGroup.name}}
            within our supplier network."
                        footerText="FOURSOURCE is the world’s largest sourcing network in the apparel industry.">
      </app-header-image>
      <div class="body-content">
        <h3 class="menu">{{ fabricGroup.name }} suppliers</h3>
        <div class="country-section" *ngFor="let country of fabricGroup.list">
          <a class="menu country-link"
             [routerLink]="'/fabrics/' + fabricGroup.uid + '/' + country.uid">{{country.name}}</a>
          <div class="row">
            <a *ngFor="let city of country.cities; let i = index;"
               [routerLink]="'/fabrics/' + fabricGroup.uid + '/' + country.uid + '/' + city.uid"
               class="city col-md-4" [class.city-background]="i%2 === 0">
              {{city.name}}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #loadingState>
  <div class="container">
    <div class="content-container-fat main-container">
      <img class="img-fluid" src="assets/img/indexpagesplaceholder.svg" alt="">
    </div>
  </div>
</ng-template>
