import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderImageComponent } from './header-image/header-image.component';
import { ItemCardComponent } from './item-card/item-card.component';
import { MatCardModule } from '@angular/material/card';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [HeaderImageComponent, ItemCardComponent],
  imports: [CommonModule, RouterModule, MatCardModule],
  exports: [HeaderImageComponent, ItemCardComponent]
})
export class CategoriesSharedModule {}
