import { Component, Inject, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CONSTANTS, makeSymbolPath, UrlResolver } from '@app/common';
import { SeoService } from '@app/common/services/seo.service';
import { Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { FabricTypesService } from '../fabric-types.service';
import { FabricGroups } from '@app/containers/categories/fabrics/fabric-types.typings';

@Component({
  selector: 'app-fabric-types',
  templateUrl: './fabric-types.component.html',
  styleUrls: ['./fabric-types.component.scss'],
})
export class FabricTypesComponent implements OnInit {
  public fabric_groups: Observable<FabricGroups>;
  public entityImagePath: string;

  constructor(
    private localService: FabricTypesService,
    private meta: Meta,
    private title: Title,
    private seoService: SeoService,
    @Inject(DOCUMENT) private dom: Document
  ) {
    this.seoService.createCanonicalURL();

    const el = this.dom.createElement('p');
    const el_2 = this.dom.createElement('p');
    const html = '&#10003;';
    const html_2 = '&#9654;';
    el.innerHTML = html;
    el_2.innerHTML = html_2;
    this.title.setTitle('Fabric Suppliers Directory | FOURSOURCE');
    this.meta.updateTag({
      name: 'description',
      content:
        'Fabric Suppliers Directory ' +
        el.innerHTML +
        ' Industry specific search ' +
        el.innerHTML +
        ' Access to a global database ' +
        el.innerHTML +
        ' Digital supplier showrooms ' +
        el_2.innerHTML +
        ' Sign up FREE on FOURSOURCE',
    });
  }

  public ngOnInit(): void {
    this.setEntityPaths();
    this.fabric_groups = this.localService.getFabricGroups();
  }

  public getImgPath(uid: string): string {
    return this.entityImagePath + uid + '.jpg';
  }

  public getBackgroundImg(): string {
    return '/assets/img/manufacturerdefault.jpg';
  }

  private setEntityPaths(): void {
    this.entityImagePath = this.localService.getStaticImagePath();
  }
}
