import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CONSTANTS } from '@app/common/constants';
import { UrlResolver } from '@app/common/url';
import { Observable } from 'rxjs';
import { first, pluck } from 'rxjs/operators';
import {
  Country,
  CountryCityDetails,
  FabricGroup,
  FabricGroups,
} from '@app/containers/categories/fabrics/fabric-types.typings';
import { makeSymbolPath } from '@app/common';
import { CONFIGURATION } from '@app/app.config';

export interface CountryDetailsParams {
  fabric_group_uid: string;
  country_uid: string;
  city_uid?: string;
}

@Injectable({
  providedIn: 'root',
})
export class FabricTypesService {
  constructor(private http: HttpClient, @Inject(UrlResolver) private url: UrlResolver) {}

  public getFabricGroups(): Observable<FabricGroups> {
    const endpoint = this.url.get(CONSTANTS.FABRIC_GROUPS, { entity: CONFIGURATION.BE_CONFIGS.FABRIC_ENTITY });

    return this.http.get(endpoint).pipe(first(), pluck('data'));
  }

  public getFabricGroupDetails(fabric_group_uid: string): Observable<FabricGroup> {
    const endpoint = this.url.get(CONSTANTS.FABRIC_GROUP_DETAILS, {
      entity: CONFIGURATION.BE_CONFIGS.FABRIC_ENTITY,
      uid: fabric_group_uid,
    });

    return this.http.get(endpoint).pipe(first(), pluck('data'));
  }

  public getFabricGroupCountryCityDetails(params: CountryDetailsParams): Observable<CountryCityDetails> {
    const endpoint =
      params && params.city_uid
        ? this.url.get(CONSTANTS.FABRIC_GROUP_COUNTRY_CITY_DETAILS, {
            entity: CONFIGURATION.BE_CONFIGS.FABRIC_ENTITY,
            uid: params.fabric_group_uid,
            country: params.country_uid,
            city: params.city_uid,
          })
        : this.url.get(CONSTANTS.FABRIC_GROUP_COUNTRY_DETAILS, {
            entity: CONFIGURATION.BE_CONFIGS.FABRIC_ENTITY,
            uid: params.fabric_group_uid,
            country: params.country_uid,
          });

    return this.http.get(endpoint).pipe(first(), pluck('data'));
  }

  public getFooterInfo(params: CountryDetailsParams): Observable<Country[]> {
    const endpoint = this.url.get(CONSTANTS.FABRIC_GROUP_COUNTRIES, {
      entity: CONFIGURATION.BE_CONFIGS.FABRIC_ENTITY,
      uid: params.fabric_group_uid,
    });

    return this.http.get(endpoint).pipe(first(), pluck('data'));
  }

  public getStaticImagePath(folder: string = 'fabric-types'): string {
    const staticDomain = this.url.get(makeSymbolPath([CONSTANTS.ENDPOINTS, CONSTANTS.STATICIMAGESDOMAIN]));

    return `${staticDomain}${folder}/md/`;
  }
}
