import { Component, OnInit, Inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CONSTANTS, makeSymbolPath, UrlResolver } from '@app/common';
import { SeoService } from '@app/common/services/seo.service';
import { get } from 'lodash';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CategoriesService } from '../../categories.service';
import { CategoryDetailsModel } from '../../categories.typings';
import { DOCUMENT } from '@angular/common';
import { FabricTypesService } from '../fabric-types.service';
import { FabricGroup } from '@app/containers/categories/fabrics/fabric-types.typings';

@Component({
  templateUrl: './fabric-type-view.component.html',
  styleUrls: ['./fabric-type-view.component.scss'],
})
export class FabricTypeViewComponent implements OnInit {
  public fabricGroupsDetails: Observable<FabricGroup>;
  public entityImagePath: string;

  constructor(
    private url: UrlResolver,
    private router: Router,
    private localService: FabricTypesService,
    private activeRoute: ActivatedRoute,
    private title: Title,
    private meta: Meta,
    private seoService: SeoService,
    @Inject(DOCUMENT) private dom: Document
  ) {
    this.seoService.createCanonicalURL();
  }

  public ngOnInit(): void {
    this.setEntityPaths();

    this.activeRoute.params.forEach((params: Params) => {
      const fabricType = get(params, 'fabric-type', null);
      this.fabricGroupsDetails = this.localService.getFabricGroupDetails(fabricType).pipe(
        tap(res => {
          this.title.setTitle(res.name + ' Fabric suppliers | FOURSOURCE');
          const el = this.dom.createElement('p');
          const el_2 = this.dom.createElement('p');
          const html = '&#10003;';
          const html_2 = '&#9654;';
          el.innerHTML = html;
          el_2.innerHTML = html_2;
          this.meta.updateTag({
            name: 'description',
            content:
              res.name +
              ' Fabric Suppliers Directory ' +
              el.innerHTML +
              ' Industry specific search ' +
              el.innerHTML +
              ' Access to a global database ' +
              el.innerHTML +
              ' Digital supplier showrooms ' +
              el_2.innerHTML +
              ' Sign up FREE',
          });
        })
      );
    });
  }

  public getImgPath(uid: string): string {
    return this.entityImagePath + uid + '.jpg';
  }

  public getBackgroundImg(): string {
    return '/assets/img/manufacturerdefault.jpg';
  }

  private setEntityPaths() {
    this.entityImagePath = this.localService.getStaticImagePath();
  }
}
