import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CategoriesComponent } from './manufacturers/categories/categories.component';
import { CategoryViewComponent } from './manufacturers/category-view/category-view.component';
import { CategoryDetailComponent } from './manufacturers/category-detail/category-detail.component';
import { FabricTypeViewComponent } from '@app/containers/categories/fabrics/fabric-type-view/fabric-type-view.component';
import { FabricTypeDetailComponent } from '@app/containers/categories/fabrics/fabric-type-detail/fabric-type-detail.component';
import { FabricTypesComponent } from '@app/containers/categories/fabrics/fabric-types/fabric-types.component';

const routes: Routes = [
  {
    path: 'manufacturers',
    children: [
      {
        path: '',
        component: CategoriesComponent,
      },
      {
        path: ':category',
        component: CategoryViewComponent,
      },
      {
        path: ':category/:country',
        component: CategoryDetailComponent,
      },
      {
        path: ':category/:country/:city',
        component: CategoryDetailComponent,
      },
    ],
  },
  {
    path: 'fabrics',
    children: [
      {
        path: '',
        component: FabricTypesComponent,
      },
      {
        path: ':fabric-type',
        component: FabricTypeViewComponent,
      },
      {
        path: ':fabric-type/:country',
        component: FabricTypeDetailComponent,
      },
      {
        path: ':fabric-type/:country/:city',
        component: FabricTypeDetailComponent,
      },
      {
        path: '**',
        redirectTo: '/fabrics',
      },
    ],
  },
  {
    path: 'profile',
    loadChildren: '../../../app/containers/profile/profile.module#ProfileModule',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CategoriesRoutingModule {}
