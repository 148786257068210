import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CategoriesRoutingModule } from './categories-routing.module';
import { CategoriesComponent } from './manufacturers/categories/categories.component';
import { MatCardModule } from '@angular/material';
import { HttpClient } from '@angular/common//http';
import { HttpClientModule } from '@angular/common/http';
import { CategoryViewComponent } from './manufacturers/category-view/category-view.component';
import { CategoryDetailComponent } from './manufacturers/category-detail/category-detail.component';
import { FabricTypeViewComponent } from './fabrics/fabric-type-view/fabric-type-view.component';
import { FabricTypeDetailComponent } from './fabrics/fabric-type-detail/fabric-type-detail.component';
import { FabricTypesComponent } from './fabrics/fabric-types/fabric-types.component';
import { CategoriesSharedModule } from './shared/categories-shared.module';
import { DirectivesModule } from '@app/directives/directives.module';

@NgModule({
  declarations: [
    CategoriesComponent,
    CategoryViewComponent,
    CategoryDetailComponent,
    FabricTypesComponent,
    FabricTypeViewComponent,
    FabricTypeDetailComponent,
  ],
  imports: [
    CommonModule,
    CategoriesRoutingModule,
    MatCardModule,
    HttpClientModule,
    CategoriesSharedModule,
    DirectivesModule,
  ],
  providers: [HttpClient]
})
export class CategoriesModule {}
