import { HttpClient } from '@angular/common/http';
import { CONSTANTS } from '@app/common/constants';
import { UrlResolver } from '@app/common/url';
import { first, pluck } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../common/url";
var CategoriesService = /** @class */ (function () {
    function CategoriesService(_http, _url) {
        this._http = _http;
        this._url = _url;
    }
    CategoriesService.prototype.getCategories = function () {
        var endpoint = this._url.get(CONSTANTS.CATEGORIES_JSON, { subdomain: 'categories' });
        return this._http.get(endpoint).pipe(first(), pluck('data'));
    };
    CategoriesService.prototype.getCategoryDetails = function (category_uid) {
        var endpoint = this._url.get(CONSTANTS.CATEGORY_DETAILS, {
            subdomain: 'categories',
            uid: category_uid,
        });
        return this._http.get(endpoint).pipe(first(), pluck('data'));
    };
    CategoriesService.prototype.getCategoryCountryDetails = function (params) {
        var endpoint = params && params.city_uid
            ? this._url.get(CONSTANTS.CATEGORY_CITY_DETAILS, {
                subdomain: 'categories',
                category: params.category_uid,
                country: params.country_uid,
                city: params.city_uid,
            })
            : this._url.get(CONSTANTS.CATEGORY_COUNTRY_DETAILS, {
                subdomain: 'categories',
                category: params.category_uid,
                country: params.country_uid,
            });
        return this._http.get(endpoint).pipe(first(), pluck('data'));
    };
    CategoriesService.prototype.getFooterInfo = function (params) {
        var endpoint = params && params.city_uid
            ? this._url.get(CONSTANTS.COUNTRY_FOOTER_INFO, {
                subdomain: 'categories',
                category: params.category_uid,
                country: params.country_uid,
            })
            : this._url.get(CONSTANTS.CITY_FOOTER_INFO, {
                subdomain: 'categories',
                category: params.category_uid,
                country: params.country_uid,
                city: params.city_uid,
            });
        return this._http.get(endpoint).pipe(first(), pluck('data'));
    };
    CategoriesService.ngInjectableDef = i0.defineInjectable({ factory: function CategoriesService_Factory() { return new CategoriesService(i0.inject(i1.HttpClient), i0.inject(i2.UrlResolver)); }, token: CategoriesService, providedIn: "root" });
    return CategoriesService;
}());
export { CategoriesService };
