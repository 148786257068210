export const environment = {
  production: true,
  host_environment: 'live',
  app_url: 'https://prod-public.foursource.com',
  api_url: 'https://public-pages.foursource.com/v1/fourall',
  pro_url: 'https://pro.foursource.com',
  static_content: 'https://cf-prod-static.foursource.com',
  subdomain: 'public',
  profile_domain: 'https://prod-public.foursource.com',
  gtag: 'GTM-TRDZJ85'
};
