import { HttpClient } from '@angular/common/http';
import { CONSTANTS } from '@app/common/constants';
import { UrlResolver } from '@app/common/url';
import { first, pluck } from 'rxjs/operators';
import { makeSymbolPath } from '@app/common';
import { CONFIGURATION } from '@app/app.config';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../common/url";
var FabricTypesService = /** @class */ (function () {
    function FabricTypesService(http, url) {
        this.http = http;
        this.url = url;
    }
    FabricTypesService.prototype.getFabricGroups = function () {
        var endpoint = this.url.get(CONSTANTS.FABRIC_GROUPS, { entity: CONFIGURATION.BE_CONFIGS.FABRIC_ENTITY });
        return this.http.get(endpoint).pipe(first(), pluck('data'));
    };
    FabricTypesService.prototype.getFabricGroupDetails = function (fabric_group_uid) {
        var endpoint = this.url.get(CONSTANTS.FABRIC_GROUP_DETAILS, {
            entity: CONFIGURATION.BE_CONFIGS.FABRIC_ENTITY,
            uid: fabric_group_uid,
        });
        return this.http.get(endpoint).pipe(first(), pluck('data'));
    };
    FabricTypesService.prototype.getFabricGroupCountryCityDetails = function (params) {
        var endpoint = params && params.city_uid
            ? this.url.get(CONSTANTS.FABRIC_GROUP_COUNTRY_CITY_DETAILS, {
                entity: CONFIGURATION.BE_CONFIGS.FABRIC_ENTITY,
                uid: params.fabric_group_uid,
                country: params.country_uid,
                city: params.city_uid,
            })
            : this.url.get(CONSTANTS.FABRIC_GROUP_COUNTRY_DETAILS, {
                entity: CONFIGURATION.BE_CONFIGS.FABRIC_ENTITY,
                uid: params.fabric_group_uid,
                country: params.country_uid,
            });
        return this.http.get(endpoint).pipe(first(), pluck('data'));
    };
    FabricTypesService.prototype.getFooterInfo = function (params) {
        var endpoint = this.url.get(CONSTANTS.FABRIC_GROUP_COUNTRIES, {
            entity: CONFIGURATION.BE_CONFIGS.FABRIC_ENTITY,
            uid: params.fabric_group_uid,
        });
        return this.http.get(endpoint).pipe(first(), pluck('data'));
    };
    FabricTypesService.prototype.getStaticImagePath = function (folder) {
        if (folder === void 0) { folder = 'fabric-types'; }
        var staticDomain = this.url.get(makeSymbolPath([CONSTANTS.ENDPOINTS, CONSTANTS.STATICIMAGESDOMAIN]));
        return "" + staticDomain + folder + "/md/";
    };
    FabricTypesService.ngInjectableDef = i0.defineInjectable({ factory: function FabricTypesService_Factory() { return new FabricTypesService(i0.inject(i1.HttpClient), i0.inject(i2.UrlResolver)); }, token: FabricTypesService, providedIn: "root" });
    return FabricTypesService;
}());
export { FabricTypesService };
