<ng-container *ngIf="(fabric_groups | async) as elements else loadingState">
    <header parallaxHeader class="w-100">
      <img class="w-100" height="400px" [src]="getBackgroundImg()" alt="">
    </header>
    <div class="container">
      <div class="blurred content-container-fat main-container pb-0">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Suppliers directory</li>
          </ol>
        </nav>
        <div class="header-content">
          <h1 class="headline"><b>FOURSOURCE</b> <br /> <span>the world's largest sourcing network</span></h1>
          <h2 class="menu">In our suppliers network you find {{elements.total_fabrics}} suppliers
            of {{elements.total_countries}} countries in {{elements.total_cities}} cities all over the world. Select one
            of the {{elements.total_entries}} fabric types below to find the perfect supplier for your plannings.</h2>
          <p class="menu">FOURSOURCE is the world’s largest sourcing network in the apparel industry.</p>
          <a href="https://foursource.com/signup" target="_blank" rel="noopener" class="btn btn-success">Join for free</a>
        </div>
        <div class="body-content">
          <h3 class="menu">Fabric suppliers</h3>
          <div class="row">
            <app-item-card *ngFor="let fabric_group of elements.list" class="col-md-3 col-sm-6"
                           [itemName]="fabric_group.name"
                           [link]="'/fabrics/' + fabric_group.uid"
                           [image]="getImgPath(fabric_group.uid)"></app-item-card>
          </div>
        </div>
      </div>
    </div>
</ng-container>
<ng-template #loadingState>
  <div class="container">
    <div class="content-container-fat main-container">
      <img class="img-fluid" src="assets/img/indexpagesplaceholder.svg" alt="">
    </div>
  </div>
</ng-template>


